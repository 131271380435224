import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../utils/seo"
import richText from '../utils/richText'

const Post = ({ data }) => {
  const { title, contingut } = data.post
  const descripcio = richText(contingut.json)

  return (
    <Layout> 
      <SEO title={title} />
      <div style={{ width: '90%', margin: '0 auto' }}>
        <br /><br />
        <div className="descripcio">
          {descripcio}
        </div>
        
      </div>
    </Layout>
  )
}
export default Post
export const pageQuery = graphql`
  query($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }){
        id
        title
        contingut {
          json
        }
        slug
    }
  }
`
